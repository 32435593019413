import React, { useContext, useMemo, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";

import { advanceBilling, fileIcon} from "../../../utils/ImportingImages/ImportingImages";
import PageHeader from "../../../templates/PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import AdvanceBillingTable from "./AdvanceBillingTable";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import ReactTableSkeleton from "../../../templates/ReactTableSkeleton";
import { AdvanceBillingJobModal } from "./AdvanceBillingJobModel";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
// import { DeleteJobModal } from "./DeleteJobModal";
// import { EditJobModal } from "./EditJobModal";
// import { AddJobsModal } from "./AddJobsModal";

const AdvancBillingContent = ({ advancBillingJobsData, setIsUpdated, isLoading }) => {
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const {getAllAdvanceBillingJobs } = useContext(ContextAPI);
  useEffect(() => {
    getAllAdvanceBillingJobs()
  }, []);

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/dashboard",
    },
    {
      pageName: "Advance Billing Jobs",
      pageURL: "/advance-billing-jobs",
    },
  ];

  const tableColumns = [
    {
      Header: "Sr no.",
      accessor: "sr no.",
      Cell: ({ row }) => row.index + 1,
    },
    // {
    //   Header: "BPO No",
    //   accessor: "bpo_no",
    // },
    {
      Header: "Job Name",
      accessor: "job_name",
    },
    {
      Header: "Client Name",
      accessor: "client_name",
    },
    // {
    //     Header:"Category",
    //     accessor:"assigned_on"
    // },
    {
        Header:"Assigned on",
        accessor:"assigned_on"
    },
    {
      Header: "Allocated Hours",
      accessor: "allocated_hours",
      Cell: ({ row }) => {
        const time = row.original.allocated_hours;
        return time.slice(0, -3);
      }
    },
    
    {
        Header: "Status",
        accessor: "job_status",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center align-items-center">
          <Stack direction="horizontal">
            {row.original.job_status === "Completed" ? (
              <Badge bg="success">Completed</Badge>
            ) : row.original.job_status === "On Hold" ? (
              <Badge bg="danger">On Hold</Badge>
            ) : (
              <Badge bg="warning" text="dark">
                In Progress
              </Badge>
            )}
          </Stack>
        </div>
        ),
      },
    
    // {
    //   Header: "Edit",
    //   Cell: ({ row }) => (
    //     <div className="table-actions-wrapper d-flex justify-content-end align-items-center">
    //       <Tooltip
    //         id="edit-job-tooltip"
    //         style={{
    //           background: "#000",
    //           color: "#fff",
    //         }}
    //         opacity={0.9}
    //       />
    //       <div
    //         data-tooltip-id="edit-job-tooltip"
    //         data-tooltip-content="Edit Job Details"
    //         data-tooltip-place="top"
    //       >
    //         {/* <EditJobModal jobData={row.original} setIsUpdated={setIsUpdated} /> */}
    //       </div>

    //       <Tooltip
    //         id="delete-job-tooltip"
    //         style={{
    //           background: "#000",
    //           color: "#fff",
    //         }}
    //         opacity={0.9}
    //       />
    //       <div
    //         data-tooltip-id="delete-job-tooltip"
    //         data-tooltip-content="Delete Job"
    //         data-tooltip-place="top"
    //       >
    //         {/* <DeleteJobModal
    //           jobData={row.original}
    //           setIsUpdated={setIsUpdated}
    //         /> */}
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const columnHeaders = [
    "Sr no.",
    "Assigned On",
    "Job Name",
    "Client Name",
    "Status",
    "Edit",
  ];

  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => advancBillingJobsData, [advancBillingJobsData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

    // constructing headers for CSV Link
    const headers = {
      headings: [
        { label: "Job Name", key: "job_name" },
        { label: "Client Name", key: "client_name" },
        { label: "Assigned On", key: "assigned_on" },
        { label: "Status", key: "job_status" },
      ],
      fileName: "Advance Billing Jobs",
    };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <div className="relative-wrapper zIndex-2">
        <PageHeader
          tableInstance={tableInstance}
          icon={advanceBilling}
          headerTitle={"Advance Billing Jobs"}
        >
          <AdvanceBillingJobModal setIsUpdated={setIsUpdated} />
        </PageHeader>
      </div>

      {/* Advance billing Jobs Table */}
      <div className="mt-5">
        {isLoading ? (
          <ReactTableSkeleton columnHeaders={columnHeaders} />
        ) : advancBillingJobsData?.length > 0 ? (
          <AdvanceBillingTable
            tableInstance={tableInstance}
            headers={headers}
            advancBillingJobsData={advancBillingJobsData}
          />
        ) : (
          <div className="mr-40 ml-30 mb-15">
            <h5>No advance billing jobs Found, Please create new Advance Billing Job!</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancBillingContent;
