import React, { useContext, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import PageHeader from "./PageHeader";
import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import styles from "./Invoices.module.css";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { Tooltip } from "react-tooltip";
import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import { PlusIconSVG } from "../../../utils/ImportingImages/ImportingImages";
import InvoiceHeader from "../../Invoices/Components/InvoiceHeader";
import InvoiceFooter from "../../Invoices/Components/InvoiceFooter";
import InvoiceClientDetails from "../../Invoices/Components/InvoiceClientDetails";
import { EditSVG } from "../../../utils/ImportingImages/ImportingImages";
import removeImg from "../../../assets/images/remove.png";
const animatedComponents = makeAnimated();
const breadCrumbs = [
  {
    pageName: "Home",
    pageURL: "/dashboard",
  },
  {
    pageName: "Invoice",
    pageURL: "/invoice",
  },
];

const taxOptions = [
  {
    label: "VAT 5%",
    value: "5",
  },
  {
    label: "VAT 10%",
    value: "10",
  },
  {
    label: "VAT 15%",
    value: "15",
  },
  {
    label: "VAT 20%",
    value: "20",
  },
];

const paymentOptions = [
  { label: "Unpaid", value: "1" },
  { label: "Partially Paid", value: "2" },
  { label: "Paid", value: "3" },
];

const AdvanceBillingInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mainURL, logout, userDetails } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);

  const userId = localStorage.getItem("userId") ?? null;
  // const { invoiceMeta, assignId, isInvoicePreview } = location?.state;
  const { invoiceMeta, assignId, isInvoicePreview } = location.state || {};

  // if (!assignId || !invoiceMeta) navigate(-1);
  // States
  const [isPreview, setIsPreview] = useState(isInvoicePreview ?? false);
  const [showError, setShowError] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editable, setEditable] = useState(true);

  const [invoice, setInvoice] = useState({
    hoursTime: 0,
    minsTime: 0,
    totalPrice: null,
    subTotal: null,
    paymentStatus: "",
    // tax: [],
    tax: [taxOptions.find(option => option.value === "20")],
    note: "",
    dueAmount: 0,
    partialPaidAmount: 0,
    client: {},
    date: "",
    billingRate: 0,
    description: "",
    servicePrice: 0,
    finalTotal: 0,
    invoiceCode: "",
    subTotalAmount: 0,
    isInvoiceGenerated: false,
    clientHasPaid: 0,

  });



  const getLabourPrice = (servicePrice) => {
    return Math.round(parseInt(servicePrice));
  };

  useEffect(() => {
    const totalPrice = getLabourPrice(invoice.servicePrice);
    setInvoice((prev) => ({
      ...prev,
      totalPrice,
    }));
  }, [invoice.servicePrice]);

  const calculateTax = (percent) => {
    const tax = Math.round((invoice?.billingRate * parseFloat(percent)) / 100);
    return +tax;
  };
  useEffect(() => {
    const calculateTotals = () => {
      // Calculate total amount for newOtherJobs

      // Calculate subtotal
      const subTotal = Math.round(invoice.billingRate);

      // Calculate total VAT amount
      const totalTaxAmount = invoice.tax.reduce((acc, tax) => {
        const taxAmount = Math.round((tax.value * subTotal) / 100);
        return acc + taxAmount;
      }, 0);

      // Calculate final total
      const finalTotal = Math.round(subTotal + totalTaxAmount);

      // Update invoice state
      setInvoice((prev) => ({
        ...prev,
        subTotal,
        finalTotal,
      }));
    };

    if (!isInvoicePreview && !invoice.isInvoiceGenerated) {
      calculateTotals();
    }
  }, [
    invoice.billingRate,
    invoice.tax,
    isInvoicePreview,
  ]);
console.log("isInvoicePreview",invoice.isInvoiceGenerated)
  // const previewInvoice = async () => {
  //   const url = invoiceMeta?.invoice_id
  //     ? `${mainURL}preview/invoice/minimum-balance/${userId}/${invoiceMeta.invoice_id}`
  //     : `${mainURL}preview/invoice/post-draft-changes/${userId}/${invoiceMeta?.post_draft_invoice_id}`;

  //   try {
  //     const result = await axios.get(url, {
  //       headers: headerOptions(),
  //     });

  //     if (result.status === 200) {
  //       setIsPreview(result?.data?.invoice_data?.is_invoice_genrated === "Yes");

  //       const additionalJobs = result.data?.invoice_data?.additioanl_jobs;

  //       if (additionalJobs) {
  //         const additionalJobsArray = Object.values(additionalJobs).map(
  //           (job) => ({
  //             id: job.job_id,
  //             task_assign_id: job.task_assign_id,
  //             jobName: job.job_name,
  //             // totalTime: job.total_time,
  //             totalAmount: job.total_amount,
  //             description: job.job_description,
  //             billingRate: job.minimum_balance,
  //           })
  //         );

  //         setInvoice((prevInvoice) => ({
  //           ...prevInvoice,
  //           additionalJobs: additionalJobsArray,
  //         }));
  //       }

  //       // setting time in the Hours & Minutes input boxes
  //       const calculateTime = (time) => {
  //         // Use regular expressions to extract hours and minutes
  //         const regex = /(\d+)hr (\d+)min/;
  //         const match = time?.match(regex);

  //         if (match) {
  //           const hours = parseInt(match[1], 10);
  //           const minutes = parseInt(match[2], 10);
  //           setInvoice((prev) => ({
  //             ...prev,
  //             hoursTime: hours,
  //             minsTime: minutes,
  //             totalTime: minutes,
  //           }));
  //         }
  //       };
  //       const calculateOtherJobTime = (otherJobs) => {
  //         if (!otherJobs) return;

  //         const updatedJobs = Object.values(otherJobs).map((job) => {
  //           const total_time = job.total_time;
  //           const regex = /(\d+)hr (\d+)min/;
  //           const match = total_time?.match(regex);

  //           if (match) {
  //             const hours = parseInt(match[1], 10);
  //             const minutes = parseInt(match[2], 10);

  //             return {
  //               ...job,

  //               hoursTime1: hours,
  //               minsTime1: minutes,
  //             };
  //           }
  //           return job;
  //         });

  //         setInvoice((prev) => ({
  //           ...prev,
  //           otherJobs: updatedJobs,
  //         }));
  //       };

  //       calculateTime(result?.data?.invoice_data?.total_time);

  //       calculateOtherJobTime(result?.data?.invoice_data?.other_jobs);

  //       const billingRate = +result.data.invoice_data.minimum_balance;
  //       console.log("billingRate", result.data.invoice_data.minimum_balance);

  //       setInvoice((prev) => ({
  //         ...prev,
  //         servicePrice: billingRate,
  //       }));

  //       const setTax = (taxArray) => {
  //         if (!Array.isArray(taxArray)) {
  //           // return [];
  //           return [taxOptions.find((option) => option.value === "20")];
  //         }

  //         return taxArray.reduce((acc, curr) => {
  //           const keys = Object.keys(curr);
  //           const values = Object.values(curr);

  //           const label = `${keys[0]} ${values[0]?.split(" ")[0]}`;
  //           const value = values[1];

  //           const newObj = { label, value };
  //           acc = [...acc, newObj];
  //           return acc;
  //         }, []);
  //       };

  //       const setPaymentStatus = (status) => {
  //         const ans = paymentOptions.find((option) => option.value === status);
  //         return ans;
  //       };

  //       const newObject = {
  //         servicePrice: billingRate,
  //         date: result.data.invoice_data?.invoice_date,
  //         jobName: result.data.invoice_data?.job_name,
  //         description: result.data.invoice_data?.job_description,
  //         subTotalAmount:
  //           result.data.invoice_data?.is_post_draft_invoice === "1"
  //             ? result.data.invoice_data?.base_amount
  //             : result.data.invoice_data?.sub_total_amount,
  //         invoiceCode: result.data.invoice_data?.invoice_code,
  //         dueAmount: result.data.invoice_data?.due_amount,
  //         partialPaidAmount: result.data.invoice_data?.partial_paid_amount,
  //         isInvoiceGenerated: Boolean(
  //           result.data.invoice_data?.is_invoice_genrated === "Yes"
  //         ),
  //         isPostDraftInvoice: Boolean(
  //           result.data.invoice_data?.is_post_draft_invoice === "1"
  //         ),
  //         isPostDraftInvoiceGenerated: Boolean(
  //           result.data.invoice_data?.is_post_draft_invoice_generated === "1"
  //         ),
  //         tax: setTax(result.data.invoice_data?.taxes),
  //         paymentStatus: isInvoicePreview
  //           ? setPaymentStatus(result.data.invoice_data?.payment_status)
  //           : result.data.invoice_data?.is_invoice_genrated === "Yes"
  //           ? setPaymentStatus(result.data.invoice_data?.payment_status)
  //           : "",
  //         finalTotal:
  //           isInvoicePreview ||
  //           result.data.invoice_data?.is_invoice_genrated === "Yes"
  //             ? result.data.invoice_data?.total_ammount
  //             : 0,
  //         note: isInvoicePreview
  //           ? result.data.invoice_data?.notes
  //           : "No notes available",
  //         client: {
  //           name: result.data.invoice_data?.client_name,
  //           email: result.data.invoice_data?.client_email,
  //           allEmails: result.data.invoice_data?.client_all_emails,
  //           company: result.data.invoice_data?.client_company_name,
  //           address: result.data.invoice_data?.company_address,
  //           contact: result.data.invoice_data?.company_contact_details,
  //         },
  //       };
  //       // console.log("newObject: ", newObject);
  //       setInvoice((prev) => ({ ...prev, ...newObject }));
  //     }
  //   } catch (e) {
  //     console.error("invoice:", e);

  //     handleAPIError(e, logout);
  //   }
  // };

  // useEffect(() => {
  //   previewInvoice();
  // }, [isUpdated]);





  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setInvoice((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  const handleEditBillingRateClick = () => {
    setEditable(!editable);
  };

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      {/* Top header section */}
      <PageHeader
        isPreview={isPreview}
        setIsPreview={setIsPreview}
        invoice={invoice}
        assignId={assignId}
        invoiceMeta={invoiceMeta}
        isInvoicePreview={isInvoicePreview}
        setInvoice={setInvoice}
        setIsUpdated={setIsUpdated}
      />

      {/* Actual Invoice Content */}
      <section className={`mr-40 mt-5 ml-30 mb-15`}>
        <div className="card">
          {/* invoice header */}
          <InvoiceHeader invoice={invoice} />
          {/* invoice body */}
          <div className="card-body">
            <InvoiceClientDetails invoice={invoice} />
            {/* billing details table */}
            <div className={`table-responsive-sm ${styles.invoiceTable}`}>
              <table className={`table table-striped`}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    {/* <th>Job Name</th> */}
                    <th style={{ width: "100px" }}></th>

                    <th style={{ width: "100px" }}>Keep Amount</th>
                    {/* <th style={{ width: "250px" }}>Total Time </th> */}
                    <th style={{ width: "100px" }}></th>
                    <th style={{ width: "100px" }}>Total</th>
                    <th style={{ width: "100px" }}></th>
                  </tr>
                </thead>
                <tbody
                  style={{ verticalAlign: "middle" }}
                  className="primary-font-color"
                >
                  <>
                    <tr>
                      <td>1</td>
                      <td>
                        {editable && !isPreview ? (
                          <textarea
                          className="w-100"
                          rows={1}
                            type="text"
                            value={invoice.description}
                            onChange={(e) =>
                              handleInputChange(e, "description")
                            }
                          />
                        ) : (
                          <span >{invoice.description || "No Description"}</span>
                        )}
                      </td>
                      <td></td>

                      <td>
                        <>
                          {editable && !isPreview ? (
                            <input
                              type="number"
                              value={invoice.billingRate}
                              onChange={(e) =>
                                handleInputChange(e, "billingRate")
                              }
                            />
                          ) : (
                            <span>£ {invoice.billingRate}</span>
                          )}

                          {/* {!editable || isPreview ? <span>£ {"0"}</span> : null} */}
                        </>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        ></div>
                        {/* ) : (
                              `${job.hoursTime1 || 0} hr ${
                                job.minsTime1 || 0
                              } min`
                            )} */}
                      </td>
                      <td>£ {invoice.billingRate || "0"}</td>

                      <td>
                        <div className="d-flex justify-content-around gap-2">
                          <div onClick={handleEditBillingRateClick}>
                            <EditSVG />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-5">
                <div className="pt-3 d-flex flex-column gap-3">
                  <div className="form-group">
                    <label className={styles.heading}>Payment Status: </label>
                    {!isPreview ? (
                      <>
                        <Select
                          name="billingService"
                          className="react-select-custom-styling__container"
                          classNamePrefix="react-select-custom-styling"
                          isClearable={false}
                          onChange={(option) => {
                            setInvoice((prev) => ({
                              ...prev,
                              paymentStatus: option,
                            }));
                          }}
                          value={invoice.paymentStatus}
                          options={paymentOptions}
                        />
                      </>
                    ) : (
                      <span
                        className={`text mb-0 ${styles.status} ${
                          invoice.paymentStatus?.value === "3"
                            ? styles.statusGreen
                            : invoice.paymentStatus?.value === "2"
                            ? styles.statusYellow
                            : styles.statusRed
                        }`}
                      >
                        {invoice.paymentStatus?.label ?? "Unpaid"}
                      </span>
                    )}
                  </div>
                  {!isPreview && invoice.paymentStatus?.value === "2" && (
                    <div className="form-group">
                      <label className={styles.heading}>
                        Client has Paid:{" "}
                      </label>
                      <input
                        value={invoice.clientHasPaid}
                        onChange={(e) =>
                          setInvoice((prev) => ({
                            ...prev,
                            clientHasPaid: +e.target.value,
                          }))
                        }
                        max={invoice?.finalTotal}
                        min={0}
                        type="number"
                        placeholder="Enter Amount"
                      />
                    </div>
                  )}

                  {!isPreview && (
                    <div className="form-group">
                      <label className={styles.heading}>Tax: </label>
                      <Select
                        name="billingService"
                        className="react-select-custom-styling__container"
                        classNamePrefix="react-select-custom-styling"
                        isMulti
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={invoice.tax}
                        onChange={(option) => {
                          setInvoice((prev) => ({ ...prev, tax: option }));
                        }}
                        options={taxOptions}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* Invoice Payment and Final Calculations */}
              <div className="col-lg-4 col-sm-5 ml-auto">
                <table className={`table table-clear ${styles.invoiceTable}`}>
                  <tbody>
                    <tr>
                      <td className={styles.heading}>Subtotal</td>
                      {(isInvoicePreview || isPreview) &&
                      invoice.isInvoiceGenerated ? (
                        <td>£{invoice?.subTotalAmount}1111111</td>
                      ) : (
                        <td>£{invoice?.billingRate}</td>
                      )}
                    </tr>
                    {invoice.tax?.length > 0 &&
                      invoice.tax.map((tax, index) => (
                        <tr key={index}>
                          <td className={styles.heading}>{tax.label}</td>
                          <td>
                            £
                            {!invoice.isInvoiceGenerated
                              ? `${calculateTax(tax.value)}`
                              : tax.value
                              ? tax.value
                              : ""}
                          </td>
                        </tr>
                      ))}

                    <tr>
                      <td className={styles.heading}>Total</td>
                      <td className={styles.heading}>£{invoice?.finalTotal}</td>
                    </tr>
                    {isPreview && invoice.paymentStatus?.value === "2" ? (
                      <>
                        <tr>
                          <td className={styles.heading}>Already Paid</td>
                          <td className={styles.heading}>
                            £
                            {invoice.clientHasPaid
                              ? invoice.clientHasPaid
                              : invoice.partialPaidAmount}
                          </td>
                        </tr>
                        <tr>
                          <td className={styles.heading}>Due Amount</td>
                          <td className={styles.heading}>
                            £
                            {!invoice.isInvoiceGenerated
                              ? invoice?.finalTotal - invoice.clientHasPaid
                              : invoice?.dueAmount}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* invoice footer */}
          <InvoiceFooter
            isPreview={isPreview}
            invoice={invoice}
            setInvoice={setInvoice}
          />
        </div>
      </section>
    </div>
  );
};

export default AdvanceBillingInvoice;
