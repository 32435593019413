

import React, { useContext, useEffect, useState } from "react";
import { bellIcon1 } from "../../utils/ImportingImages/ImportingImages";
import { taskImg } from "../../utils/ImportingImages/ImportingImages";
import axios from "axios";
import { ContextAPI } from "../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../ReactHotToast/ReactHotToast";
import { nnAPIKey } from "../../Context/ApiContext/ApiContext";
import {
  formatDateTime,
  headerOptions,
} from "../../utils/utilities/utilityFunctions";
import { useNavigate } from "react-router-dom";
export const GetAllNotifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const notificationsPerPage = 20;
  const { mainURL } = useContext(ContextAPI);
  const token = localStorage.getItem("token");
  const bearer = `Bearer ${token}`;
  const [markAllChecked, setMarkAllChecked] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [updateNotification, setUpdateNotification] = useState({ operationType: "", notificationsIds: "" });

  const fetchNotifications = async (page = 1) => {
    const userId = localStorage.getItem("userId") ?? null;
    const url = `${mainURL}get/my-notification/${userId}?page=${page}`;
    
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      NN_Api_key: nnAPIKey,
      Authorization: bearer,
    };

    try {
      const result = await axios.get(url, { headers });
      if (result.status === 200) {
        setNotifications(result.data.notifications_data.notifications.map(notification => ({ ...notification, isChecked: false })));
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/unauthorized");
        ReactHotToast("Unauthorized access.");
      }
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  const updateNotifications = async () => {
    setIsLoading(true);
    const notificationIds = notifications.filter(item => item.isChecked).map(item => item.id).join(",");
    const body = {
      current_user: localStorage.getItem("userId") ?? null,
      operation_type: updateNotification.operationType,
      notifications_ids: notificationIds,
    };
    
    try {
      const result = await axios.put(`${mainURL}update/my-notification`, body, { headers: headerOptions() });
      if (result.status === 200) {
        fetchNotifications(currentPage);
        ReactHotToast(result.data.message, "success");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateNotification = (e) => {
    e.preventDefault();
    updateNotifications();
  };

  const toggleMarkAll = (event) => {
    const isChecked = event.target.checked;
    setMarkAllChecked(isChecked);
    setShowUpdateButton(isChecked);
    setNotifications(notifications.map(item => ({ ...item, isChecked })));
    
    setUpdateNotification({
      operationType: isChecked ? "all" : "",
      notificationsIds: isChecked ? notifications.map(item => item.id).join(",") : ""
    });
  };

  const toggleIndividualCheckbox = (index) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].isChecked = !updatedNotifications[index].isChecked;
    
    const allChecked = updatedNotifications.every(item => item.isChecked);
    setMarkAllChecked(allChecked);
    
    const operationType = allChecked ? "all" : updatedNotifications.some(item => item.isChecked) ? "single" : "";
    const notificationsIds = updatedNotifications.filter(item => item.isChecked).map(item => item.id).join(",");
    
    setUpdateNotification({ operationType, notificationsIds });
    setShowUpdateButton(updatedNotifications.some(item => item.isChecked));
    setNotifications(updatedNotifications);
  };

  const pageNumbers = Math.ceil(notifications.length / notificationsPerPage);
  
  return (
    <div className="main-content">
      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100">
          <img src={bellIcon1} alt="All Notifications" />
          <p className="m-0 fs-4">All Notifications</p>
        </div>
      </section>

      <div className="main-header mt-5">
        {notifications.filter(a => a.is_view === "no").length > 0 && (
          <div className="d-flex justify-content-start" style={{ gap: "10px" }}>
            <div className="mb-5">
              <input
                id="markAll"
                type="checkbox"
                onChange={toggleMarkAll}
                checked={markAllChecked}
                className="cursor-pointer checkbox-input"
              />
              <span className="pr-4"> Mark All</span>
            </div>
            {showUpdateButton && (
                <div>
              <button onClick={handleUpdateNotification} className="custom-btn">
                Update
              </button>
              </div>
            )}
          </div>
        )}

        {notifications
          .filter(a => a.is_view === "no")
          .map((e, index) => {
            const stripHTML = html => html.replace(/<[^>]*>/g, "");
            const message = stripHTML(e.notification_message);
            const title = stripHTML(e.notification_for);

            return (
              <div className="notfications-list__li block-section d-flex" key={e.id}>
                <div>
                  <input
                    type="checkbox"
                    onChange={() => toggleIndividualCheckbox(index)}
                    checked={e.isChecked}
                    className="cursor-pointer checkbox-input"
                  />
                </div>
                <div>
                  <ul>
                    <li className="notification-title">{title}</li>
                    <li className="notifications-message">{message}</li>
                    <li className="notification-date">{formatDateTime(e.notification_send_on)}</li>
                  </ul>
                </div>
              </div>
            );
          })}
          
        {notifications.filter(a => a.is_view === "no").length === 0 && (
          <p className="d-flex justify-content-center">No new notifications</p>
        )}
      </div>
      
      {notifications.length > 0 && (
        <div className="d-flex justify-content-center">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</a>
              </li>
              {Array.from({ length: pageNumbers }, (_, index) => (
                <li key={index + 1} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                  <a className="page-link" onClick={() => setCurrentPage(index + 1)}>{index + 1}</a>
                </li>
              ))}
              <li className={`page-item ${currentPage === pageNumbers ? "disabled" : ""}`}>
                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</a>
              </li>
            </ul>
          </nav>
        </div>
 )}
    </div>
  );
};
