import React, { useContext, useState, useEffect } from "react";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import AdvancBillingContent from "./AdvanceBillingContent";
import AdvancBillingJobListContent from "../AdvanceBillingJobList.js/components/AdvanceBillingJobListContent";

export const AdvancBillingJobs = () => {
  const {getAllAdvanceBillingJobs,getJobsDetailsByMemberId, initialState } = useContext(ContextAPI);
  const [isUpdated, setIsUpdated] = useState(false);
  useEffect(() => {
    getAllAdvanceBillingJobs()
    getJobsDetailsByMemberId()
    
  }, [isUpdated]);

  return (
    <>

      <AdvancBillingContent
      advancBillingJobsData={initialState?.advanceBillingJobList}
      setIsUpdated={setIsUpdated}
      isLoading={initialState?.isLoading}
    />
    {/* <AdvancBillingJobListContent
    advancBillingJobsData={initialState?.advanceBillingJobList}
    setIsUpdated={setIsUpdated}
    isLoading={initialState?.isLoading}
  /> */}
  </>
  
  );
};
